import { useEffect, useState } from 'react';
import { ErrorToast, SuccessToast } from '../components';
import { Footer } from '../components/Footer';
import { capitalizeAllFirstLetter } from '../utils';
import { InputForm } from './../components/inputFields';
import { InputFile } from './../components/inputFile';
import { SelectForm } from './../components/selectForm';
import { TextBox } from './../components/textBox';
import { getDropdownValuesAPI, postFormData, postImage } from './../services';
// import { useHistory } from "react-router-dom";

const REGEX_EMAIL = /\S+@\S+\.\S+/;

const Homepage = () => {
  const params = new URLSearchParams(window.location.search);
  const emailFromParams = params.get('email') || '';
  const productFromParams = params.get('product_category') || '';
  const coworkingCategory = params.get('coworking_category') || '';
  const issueFromParams = params.get('issue') || '';
  const campusId = params.get('campus_id') || undefined;
  const userAuthenticated =
    params.get('user_authenticated') === 'false'
      ? false
      : !!params.get('user_authenticated');

  // const history = useHistory();

  const [inputDataObject, setInputDataObject] = useState();

  useEffect(() => {
    const getDropdownData = async () => {
      const mocksData = await getDropdownValuesAPI();
      setInputDataObject(mocksData);
    };
    getDropdownData();
  }, []);

  const [email, setEmail] = useState(() => emailFromParams || '');
  const [errorEmail, setErrorEmail] = useState('');
  const [issue, setIssue] = useState(() => issueFromParams || '');
  const [details, setDetails] = useState('');
  const [selectedFiles, setSelectedFiles] = useState(null);
  const [selectedCampus, setSelectedCampus] = useState(null);
  const [selectedCategory, setSelectedCategory] = useState(null);
  const [selectedMaster, setSelectedMaster] = useState(null);
  const [selectedCountry, setSelectedCountry] = useState(null);
  const [showSuccessToast, setShowSuccessToast] = useState(false);
  const [showErrorToast, setShowErrorToast] = useState(false);
  const [selectedProductCategory, setSelectedProductCategory] =
    useState(productFromParams);
  const [isSubmitting, setIsSubmitting] = useState(false);

  const [forceCloseDropdown, setForceCloseDropdown] = useState(false);

  // const selectRef = useRef({});

  useEffect(() => {
    // set selected Campus from query params
    const selectedCampusFromParam =
      inputDataObject?.campuses?.length &&
      inputDataObject?.campuses?.filter(
        (campus) => campus?.id?.toString() === campusId?.toString()
      )?.[0]?.name;
    selectedCampusFromParam && setSelectedCampus(selectedCampusFromParam);
  }, [inputDataObject, campusId]);

  useEffect(() => {
    // set selected Campus from query params
    const coworkingCategoryFromParam =
      inputDataObject?.tickets_category?.length &&
      inputDataObject?.tickets_category?.filter(
        (elem) => elem?.value === coworkingCategory
      )?.[0]?.value;

    coworkingCategoryFromParam &&
      setSelectedCategory(coworkingCategoryFromParam);
  }, [inputDataObject, coworkingCategory]);

  const renderDropdowns = () => {
    if (selectedProductCategory?.toLowerCase() === 'Coworking'.toLowerCase()) {
      return (
        <div>
          <SelectForm
            data={inputDataObject?.campuses?.map((campus) => ({
              value: campus.name,
              label: campus.name,
            }))}
            labelText='Campus'
            isRequired={true}
            onChange={(value) => {
              setSelectedCampus(value);
              setSelectedCountry(null);
              setSelectedMaster(null);
            }}
            customClasses={'tag-pb-regular'}
            selectedDefaultText={'Please select the campus'}
            selectedDefaultEmpty={true}
            defaultValue={selectedCampus}
            // ref={(ref) => selectRefs.current.push(ref)}
            forceCloseDropdown={forceCloseDropdown}
            closeAllDropdowns={() => {
              setForceCloseDropdown(true);
              // selectRefs.current?.forceCloseDropdown?.();
              // ?.filter(Boolean)
              // ?.map((elem) => elem?.forceCloseDropdown?.());
              setTimeout(() => {
                setForceCloseDropdown(false);
              }, 10);
            }}
            formName={'campus'}
          />
          <SelectForm
            data={inputDataObject?.tickets_category}
            labelText='Category'
            isRequired={true}
            onChange={(value) => {
              setSelectedCategory(value);
            }}
            customClasses={'tag-pb-regular'}
            selectedDefaultText={'Please select the category'}
            defaultValue={selectedCategory}
            selectedDefaultEmpty={true}
            // ref={(ref) => selectRefs.current.push(ref)}
            forceCloseDropdown={forceCloseDropdown}
            closeAllDropdowns={() => {
              setForceCloseDropdown(true);
              // selectRefs.current?.forceCloseDropdown?.();
              // ?.filter(Boolean)
              // ?.map((elem) => elem?.forceCloseDropdown?.());
              setTimeout(() => {
                setForceCloseDropdown(false);
              }, 10);
            }}
            formName={'campus_category'}
          />
        </div>
      );
    } else if (
      selectedProductCategory?.toLowerCase() ===
      'Innovation School'.toLowerCase()
    ) {
      return (
        <SelectForm
          data={inputDataObject?.tickets_masters}
          labelText='Select your Master'
          isRequired={true}
          onChange={(value) => {
            setSelectedMaster(value);
            setSelectedCampus(null);
            setSelectedCategory(null);
            setSelectedCountry(null);
          }}
          customClasses={'tag-pb-regular'}
          selectedDefaultText={'Please select your master'}
          selectedDefaultEmpty={true}
          // ref={(ref) => selectRefs.current.push(ref)}
          forceCloseDropdown={forceCloseDropdown}
          closeAllDropdowns={() => {
            setForceCloseDropdown(true);
            // selectRefs.current?.forceCloseDropdown?.();
            // ?.filter(Boolean)
            // ?.map((elem) => elem?.forceCloseDropdown?.());
            setTimeout(() => {
              setForceCloseDropdown(false);
            }, 10);
          }}
          formName={'master'}
        />
      );
    } else if (
      selectedProductCategory?.toLowerCase() ===
      'Corporate Programmes'.toLowerCase()
    ) {
      return (
        <SelectForm
          data={inputDataObject?.tickets_country}
          labelText='Select your Country'
          isRequired={true}
          onChange={(value) => {
            setSelectedCountry(value);
            setSelectedMaster(null);
            setSelectedCampus(null);
            setSelectedCategory(null);
          }}
          customClasses={'tag-pb-regular'}
          selectedDefaultText={'Please select your country'}
          selectedDefaultEmpty={true}
          // ref={(ref) => selectRefs.current.push(ref)}
          formName={'country'}
        />
      );
    }
    return null;
  };

  const resetForms = () => {
    setIssue('');
    setDetails('');
    setSelectedCategory(null);
  };

  const onSubmit = async (event) => {
    event.preventDefault();

    var fileOptions = {
      access: 'PUBLIC_INDEXABLE',
      ttl: 'P3M',
      overwrite: false,
      duplicateValidationStrategy: 'NONE',
      duplicateValidationScope: 'ENTIRE_PORTAL',
    };

    const imagesUrls = selectedFiles?.length
      ? await Promise.all(
        [...selectedFiles].map(async (file, i) => {
          const fileData = new FormData();
          fileData.append('options', JSON.stringify(fileOptions));
          fileData.append('folderId', process.env.REACT_APP_FOLDER_ID);
          fileData.append('file', file);
          let data = null;
          await postImage(
            fileData,
            (response) => {
              console.log('success', response?.objects?.[0]?.url);
              data = response?.objects?.[0]?.url;
            },
            () => {
              console.log('error');
            }
          );
          console.log(data);
          return data;
        })
      )
      : [];

    // Closing Contract Request Checkbox	closing_contract_request
    // Campus	ticket_campus ok
    // Category	ticket_category ok
    // Details	ticket_content  ok
    // Attachments	ticket_file_upload ok
    // Product Category	ticket_product_category ok
    // Source	ticket_source
    // Issue	ticket_subject ok
    // Country (Tickets)	tickets_country ok
    // Masters (Tickets)	tickets_masters ok

    const payload = {
      submittedAt: Date.now().toString(),
      fields: [
        {
          name: 'email',
          value: email,
        },
        {
          name: 'ticket_product_category',
          value: capitalizeAllFirstLetter(selectedProductCategory),
        },
        {
          name: 'ticket_subject',
          value: issue,
        },
        {
          name: 'ticket_content',
          value: details,
        },
        selectedCategory && {
          name: 'ticket_category',
          value: selectedCategory,
        },
        !!selectedCampus && { name: 'ticket_campus', value: selectedCampus },
        !!selectedMaster && { name: 'tickets_masters', value: selectedMaster },
        !!selectedCountry && {
          name: 'tickets_country',
          value: selectedCountry,
        },
        imagesUrls?.length && {
          name: 'ticket_file_upload',
          value: imagesUrls?.filter(Boolean)?.join(', '),
        },
      ]?.filter(Boolean),
    };

    setIsSubmitting(true);
    postFormData(
      userAuthenticated,
      payload,
      () => {
        console.log('success');
        setShowSuccessToast(true);
        // history.push("/thankyou");
        resetForms();
      },
      () => {
        console.log('error');
        setShowErrorToast(true);
      }
    );
    setIsSubmitting(false);
  };

  return (
    <div
      className='App'
      onClick={() => {
        setForceCloseDropdown(true);
        setTimeout(() => {
          setForceCloseDropdown(false);
        }, 10);
      }}
    >
      <div className='container tag-pb-xlarge tag-pt-regular'>
        <img
          src='images/logo.png'
          style={{ height: '60px' }}
          alt=''
          className={'tag-mt-regular'}
        />

        <h2 className='tag-h2 tag-left_text lubalin'>Talent Garden Support</h2>
        {/* <p className="container__text tag-body_regular">Lorem Ipsum</p> */}
        <form className='form__container' onSubmit={onSubmit}>
          <InputForm
            value={email}
            onChangeText={(Text) => {
              if (!REGEX_EMAIL.test(Text)) {
                setErrorEmail('Email is not valid');
              } else {
                setErrorEmail('');
              }
              setEmail(Text);
            }}
            type={'email'}
            formName={'email'}
            labelText={'Email'}
            isRequired={true}
            placeholder={'Email'}
            id={'email'}
            customClasses={'tag-pb-regular'}
            disabled={!!emailFromParams}
            error={errorEmail}
            touched={true}
          />
          {productFromParams?.toLowerCase() !== 'Coworking'.toLowerCase() &&
            productFromParams?.toLowerCase() !==
            'Innovation School'.toLowerCase() &&
            productFromParams?.toLowerCase() !==
            'Corporate Programmes'.toLowerCase() && (
              <SelectForm
                data={inputDataObject?.productCategory}
                labelText='Product Category'
                isRequired={true}
                onChange={(value) => setSelectedProductCategory(value)}
                customClasses={'tag-pb-regular'}
                selectedDefaultText={'Please select product category'}
                selectedDefaultEmpty={true}
                // ref={selectRef}
                forceCloseDropdown={forceCloseDropdown}
                closeAllDropdowns={() => {
                  setForceCloseDropdown(true);
                  // selectRefs.current?.forceCloseDropdown?.();
                  // ?.filter(Boolean)
                  // ?.map((elem) => elem?.forceCloseDropdown?.());
                  setTimeout(() => {
                    setForceCloseDropdown(false);
                  }, 10);
                }}
                defaultValue={productFromParams}
                formName={'product_category'}
              />
            )}
          {renderDropdowns()}
          <InputForm
            value={issue}
            onChangeText={(Text) => setIssue(Text)}
            type={'text'}
            formName={'issue'}
            labelText={'Issue'}
            isRequired={true}
            placeholder={'Problem overview'}
            id={'issue'}
            customClasses={'tag-pb-regular'}
          />
          <TextBox
            value={details}
            onChangeText={(Text) => setDetails(Text)}
            type={'text'}
            formName={'details'}
            labelText={'Details'}
            isRequired={true}
            placeholder={'Describe the issue you would like help with'}
            id={'details'}
            customClasses={'tag-pb-regular'}
          />
          <InputFile
            labelText={'Attachments'}
            formName={'file_upload'}
            size={'30'}
            id={'file_upload'}
            type={'file'}
            onChange={(e) => setSelectedFiles(e.target.files)}
          />
          <p className='tag-label_regular tag-pb-regular'>
            By submitting this form, you acknowledge that you have read, understood, 
            and accepted the following terms:{' '}
            <ul>
              <li>
                <a
                  href='https://knowledge.talentgarden.com/en/kb/terms-and-conditions'
                  className='tag-link_regular'
                  target='_blank'
                  rel='noreferrer'
                >
                  General Terms and Conditions
                </a>{' | '}
                <a
                  href='https://knowledge.talentgarden.com/en/kb/right-of-withdrawal'
                  className='tag-link_regular'
                  target='_blank'
                  rel='noreferrer'
                >
                  Right of Withdrawal
                </a>{' | '}
                <a
                  href='https://knowledge.talentgarden.com/en/kb/payment-terms'
                  className='tag-link_regular'
                  target='_blank'
                  rel='noreferrer'
                >
                  Payment Terms
                </a>
              </li>
            </ul>
          </p>
          {showSuccessToast && (
            <SuccessToast
              closeToast={() => setShowSuccessToast(false)}
              showToast={showSuccessToast}
            />
          )}

          {showErrorToast && (
            <ErrorToast
              closeToast={() => setShowErrorToast(false)}
              showToast={showErrorToast}
            />
          )}

          <button
            type='submit'
            className={`tag-button tag-button__primary tag-button__primary--large tag-button__primary--orange  tag-margin-auto ${isSubmitting ? 'tag-button__primary--disabled' : null
              }`}
            disabled={isSubmitting}
          >
            Send
          </button>
        </form>
      </div>
      <Footer />
    </div>
  );
};

export default Homepage;
